<template>
  <base-view
    title="Exportar Ampliaciones, Reducciones"
    description="Exporta en formato para SAACG.NET las ampliaciones y reducciones"
    background="bg-gradient-black-to-purple"
    icon="repeat"
    header-type="overlap"
  >
    <b-card no-body>
      <b-card-header>
        <div class="row align-items-center">
          <div class="col">
            <x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" />
          </div>
        </div>
      </b-card-header>

      <b-overlay :show="Boolean(loading)">
        <template #overlay>
          <loading></loading>
        </template>
        <div class="row my-1 p-4">
          <b-table
            :fields="fields"
            :items="traspasos"
            striped
            bordered
            responsive
            hover
            stacked="lg"
            show-empty
          >
            <template #cell(concepto)="data">
              <div class="text-primary text-center text-sm w-100">
                {{ data.item.concepto }}
              </div>
            </template>
            <template #cell(fecha)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="dark" class="ml-2">{{ data.item.fecha }}</b-badge>
              </div>
            </template>
            <template #cell(folio)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ data.item.folio }}</b-badge>
              </div>
            </template>
            <template #cell(mes)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="secondary" class="ml-2">
                  {{ getMonthById(data.item.mes).value }}
                </b-badge>
              </div>
            </template>
            <template #cell(total)="data">
              <div class="text-primary text-center text-sm w-100">
                <b-badge variant="primary" class="ml-2">{{ $formatNumber(data.item.total) }}</b-badge>
              </div>
            </template>
            <template #row-details>
              <movimientos-list :traspaso="selectedTraspaso"></movimientos-list>
            </template>
            <template #cell(acciones)="row">
              <div class="d-flex justify-content-center align-items-center" style="font-size: 1rem">
                <b-button
                  class="mr-3 mb-2 mb-sm-0"
                  size="sm"
                  variant="info"
                  @click="
                    row.toggleDetails()
                    onShowMovimientos(row.item.id)
                  "
                >
                  Ver Movimientos
                </b-button>

                <check-authorization :requiresAuthorizations="['exportar ampliaciones reducciones']">
                  <b-button
                    class="mr-3 mb-2 mb-sm-0"
                    size="sm"
                    variant="primary"
                    @click="onExport(row.item.id, row.item.descripcion)"
                  >
                    Exportar
                  </b-button>
                </check-authorization>
              </div>
            </template>
          </b-table>
        </div>
      </b-overlay>
    </b-card>
  </base-view>
</template>

<script>
import { mapGetters } from 'vuex'
import MovimientosList from '@/components/RecursosFederales/MovimientosList'

export default {
  name: 'AmpliacionesReduccionesExportView',

  components: {
    MovimientosList
  },

  data () {
    return {
      fields: ['descripcion', 'fecha', 'autorizado', 'total', 'acciones'],

      loading: false,

      selectedTraspaso: null,

      traspasos: null
    }
  },

  computed: {
    ...mapGetters('UIModule', ['getMonthById'])
  },

  methods: {
    async onChangePeriodoFiscal (periodo_fiscal_id) {
      this.loading = true
      this.periodoActivo = await this.$getPeriodoFiscalActivo()

      const traspasos = await this.$store.dispatch('exportacionSAACGNETModule/listar_ampliaciones_reducciones', {
        periodo_fiscal_id: this.periodoActivo.id
      })

      this.traspasos = traspasos.data
      this.loading = false
    },

    onShowMovimientos (id) {
      this.selectedTraspaso = this.traspasos.find(el => el.id === id)
    },

    async downloadAcuse (id, folio) {
      const getval = await this.$store.dispatch('recursoFederal/downloadAcuse', id)

      await this.$onDownload(getval, `acuse.${folio}.pdf`)

      this.$notify({ message: `Solicitud de descarga de acuse de traspaso con folio ${folio}.` })
    },

    async onExport (id, folio) {
      const getval = await this.$store.dispatch(
        'exportacionSAACGNETModule/export_ampliaciones_reducciones',
        id
      )

      await this.$onDownload(getval, `ampliaciones_reducciones.${folio}.xlsx`)

      this.$notify({
        message: `Solicitud de descarga de ampliaciones/reducciones con folio ${folio}.`
      })
    }
  }
}
</script>
